import React from "react";
import { Helmet } from "react-helmet";
import { getSrc } from "gatsby-plugin-image";
import { GatsbyImageType } from "../..";

type Meta = {
  name?: string;
  property?: string;
  content: string;
};

export type SeoProps = {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title?: string;
  keywords?: string;
  image?: GatsbyImageType;
  canonicalUrl?: string;
  noIndex?: boolean;
};

export const BaseSeo: React.FC<SeoProps> = ({
  description = "",
  lang = "et",
  meta = [],
  title = "",
  keywords = "Timber",
  image,
  canonicalUrl,
  noIndex,
}) => {
  const getImageUrl = () => {
    return image?.gatsbyImageData ? getSrc(image.gatsbyImageData) : undefined;
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={
        canonicalUrl
          ? [
              {
                rel: "canonical",
                href: canonicalUrl,
              },
            ]
          : []
      }
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          property: `og:image`,
          content: getImageUrl(),
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: "robots",
          content: noIndex ? "noindex" : undefined,
        },
      ]
        .filter(item => !!item.content)
        .concat(meta as any[])}
    />
  );
};
